<template>
  <div class="fansPage flex-column-center">
    <div class="numLine flex-row-center">
      <div class="tag"></div>
      <div class="num">粉絲人數：{{fansNum}}</div>
    </div>
    <div class="dataList">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="initList"
      >
        <div
          class="userLine flex-column-center"
          v-for="(item,index) in focusList"
          :key="index"
        >
          <div
            class="divider"
            v-if="index>0"
          ></div>
          <img
            class="userIcon"
            :src="item.avatar"
            alt=""
            @click="goUserInfo(item.id)"
          >
          <div class="userName">{{item.nickname}}</div>
          <!-- <div class="userTitle">骠骑大将军</div> -->
          <!-- 关注按钮 -->
          <!-- <div
            class="followBtn flex-row-center"
            v-show="!item.followStatus && currentUserId == localUserId"
            @click="goFollow(item.id,index)"
          >
            <img
              class="followIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/followIcon.png"
              alt=""
            >
            <div class="followText">關注</div>
          </div> -->
          <!-- 取消关注按钮 -->
          <!-- <div
            class="unFollowBtn"
            v-show="item.followStatus && currentUserId == localUserId"
            @click="goUnFollow(item.id,index)"
          >互相關注</div> -->
        </div>
      </van-list>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import { getFansList, unFollowUser, followUser } from '../../api/service'
export default {
  name: 'Focus',
  data() {
    return {
      focusList: [],
      loading: false,
      finished: false,
      cursor: '',
      fansNum: 0,
      currentUserId: null,
      localUserId: null
    }
  },
  mounted() {
    this.fansNum = this.$route.query.fansNum
    this.currentUserId = this.$route.query.userId
    this.localUserId = localStorage.userId
  },
  methods: {
    initList() {
      getFansList({
        cursor: this.cursor,
        page_size: 20,
        user_id: this.currentUserId
      }).then((res) => {
        if (res.code == 0) {
          this.cursor = res.data.cursor
          res.data.list.map((item) => {
            item.followStatus = true
            this.focusList.push(item)
          })
          this.loading = false
          if (!res.data.cursor) {
            this.finished = true
          }
        }
      })
    },
    goUnFollow(id, index) {
      unFollowUser(id).then((res) => {
        if (res.data.code == 0) {
          this.focusList[index].followStatus = false
        }
      })
    },
    goFollow(id, index) {
      followUser(id).then((res) => {
        if (res.code == 0) {
          this.focusList[index].followStatus = true
        }
      })
    },
    goUserInfo(id) {
      this.$router.push({
        path: 'Author',
        query: {
          userId: id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Fans.scss';
</style>